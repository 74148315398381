<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.show_conclusions") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false"
                @click="submit">{{ $t("message.save_and_close") }}</el-button>
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-row>
            <el-col class="ml-5" :span="8">
              {{ $t("message.doctor_name") }} :
              <b>{{
                model.doctor
                ? (model.doctor.surname ? model.doctor.surname : "") +
                " " +
                model.doctor.name +
                " " +
                (model.doctor.patronymic ? model.doctor.patronymic : "")
                : ""
              }}</b>
            </el-col>
            <el-col :span="8">
              {{ $t("message.date") }} :
              <b>{{ model.date_time }}</b>
            </el-col>
            <el-divider></el-divider>
            <el-col :span="24">
              <el-card class="ml-5" style="width: 826px" shadow="always">
                <el-card shadow="always">
                  <Tinymce ref="editor" class="naw_Editor" v-model="form.content" :height="800" />
                </el-card>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import Tinymce from "@/components/Tinymce";

export default {
  mixins: [form],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    Tinymce,
  },
  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {},
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() { },
  async mounted() { },
  computed: {
    ...mapGetters({
      rules: "conclusions/rules",
      model: "conclusions/model",
      columns: "conclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      update: "conclusions/update",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.loadingButton = true;
      this.update({ id: this.form.id, data: this.form })
        .then((res) => {
          this.loadingButton = false;
          this.$emit("c-close", { drawer: "drawerUpdate" });
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.$refs["form"];
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: err.error.message
          });
        });
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
